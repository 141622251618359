import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import { get } from 'idb-keyval';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        let variableCopy = parsedParameters['variableObject'];
        if(parsedParameters.error){
            variableCopy[variableName] = {
                error : {
                    message : 'Failed to resolve function parameters.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedParams = parsedParameters['accumulator'];
        if(
            !resolvedParams.efficientFileManagerId ||
            typeof resolvedParams.efficientFileManagerId !== 'string'
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'Efficient file manager ID must be a valid string.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Efficient file manager ID must be a valid string.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let base64 = await get(resolvedParams.efficientFileManagerId);

        variableCopy[variableName] = {base64};

        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/getFromEfficientFileManagerParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}